<template>
  <div>
    <el-card>
      <!-- <el-form ref="form" :inline="true">
        <el-form-item label="类型">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择"
            clearable
            width="100px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form> -->
      <el-table
        :data="paymentList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column label="ID" prop="id" width="55" align="center"></el-table-column>
        <el-table-column label="订单编号" prop="order_sn" align="center"></el-table-column>
        <el-table-column label="课程id" prop="goods_id" align="center"></el-table-column>
        <el-table-column label="课程名称" prop="goodsInfo.goods_name" align="center"></el-table-column>
        <el-table-column label="价格" prop="price" align="center"></el-table-column>
        <el-table-column label="用户昵称" prop="userInfo.nickname" align="center"></el-table-column>
        <el-table-column label="用户电话" prop="userInfo.phone" align="center"></el-table-column>
        <el-table-column label="支付状态" prop="statusA" align="center"></el-table-column>
        <el-table-column label="支付方式" prop="typeA" align="center"></el-table-column>
        <el-table-column label="支付时间" prop="crreate_time" align="center"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <div class="pagination">
        <span></span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.page"
          :page-sizes="[3, 5, 8, 10]"
          :page-size="queryForm.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          algin:="center"
        ></el-pagination>
      </div>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  getPayOrderList
} from "@/api/paymentList.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        type: 1,
      },
      total: 0,
      paymentList: [],
    };
  },
  created() {
    this.payOrderList();
  },
  methods: {
    // 获取支付列表
    payOrderList(){
      getPayOrderList(this.queryForm).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        res.data.data.forEach((item)=>{
          if(item.status == 1){
            item.statusA = "待支付"
          }else if(item.status == 2){
            item.statusA = "支付成功"
          }
          if(item.type == 1){
            item.typeA = "微信支付"
          }else if(item.type == 2){
            item.typeA = "苹果支付"
          }
        })
        this.paymentList = res.data.data;

      })
    },
    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.payOrderList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.payOrderList() ;
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.payOrderList() ;
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  img {
    width: 120px;
    height: 200px;
  }
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
